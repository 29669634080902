<template>
  <Toast />
  <Toolbar v-if="$can('pharmasan.contabilidad.amortizaciones.compras.access')" class="box mt-4 p-p-2">
    <template #end>
      <router-link
        :to="{name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.compras.formulario'}"
      >
        <Button label="Registrar Compra" icon="pi pi-plus" class="p-button-success p-button-xs" />
      </router-link>
    </template>
  </Toolbar>

  <list-shopping />

</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
  name: 'ShoppingIndex',
  components: {
    listShopping: defineAsyncComponent(() => import('../list'))
  }
}
</script>

<style scoped>

</style>
